import loadingIcon from './assets/loadingIcon.svg';
import sinarmasLogo from './assets/sinarmas.png';

const LoadingPage = () => {
    return (
        <div className="not-found-container">
        <img src={sinarmasLogo} style={{ height: "60px" }} />
        <div className='loading-indicator'>
            <img src={loadingIcon} alt="loading" />
        </div>
    </div>
    )
}

export default LoadingPage;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import sinarmasLogo from '../assets/sinarmasLogo.svg';
import desktopIcon from '../assets/desktopIcon.svg';
import './Homepage.css';
import { useDispatch } from 'react-redux';
import { logout } from '../libs/redux/slices/authSlice';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useSelector } from 'react-redux';

const Homepage = () => {
  useDocumentTitle('Home');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
    navigate('/login');
  };

  const handleClick = (params) => {
    navigate(`/dashboard/${params}`);
  };

  // const listDashboard = [
  //   {
  //     title: 'MVD Production',
  //     icon: desktopIcon,
  //     params: 'mvd-production'
  //   },
  //   {
  //     title: 'iCON360 - SPB Exception',
  //     icon: desktopIcon,
  //     params: 'icon360-spb-exception'
  //   },
  //   {
  //     title: 'iCON360 - IPD Effectiveness',
  //     icon: desktopIcon,
  //     params: 'icon360-ipd-effectiveness'
  //   },
  //   {
  //     title: 'iCON360 - Unreconciled Denda',
  //     icon: desktopIcon,
  //     params: 'icon360-unreconciled-denda'
  //   }
  // ]

  return (
    <div className="homepage">
      <div className="banner">
        <img src={sinarmasLogo} alt="Sinarmas Logo" />
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <hr className="separator-line" />
      <div className="homepage-container">
        {user.dashboard.map((dashboard, index) => (
          <div key={index} className="box" onClick={() => handleClick(dashboard.slug)}>
            <div className='box-title'>{dashboard.dashboard_name}</div>
            <img src={desktopIcon} alt="Desktop Icon" className="desktop-icon" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Homepage;
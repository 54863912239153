import { useSelector } from "react-redux";

const SessionExpiredModal = () => {
  const { isSessionExpired } = useSelector((state) => state.auth)

  const handleLogin = () => {
    window.location.href = '/login';
  }

  if (!isSessionExpired) return null;

  return (
    <div className="session-expired__overlay">
      <div className="session-expired__modal">
        <div className="session-expired__content">
          <h1 className="session-expired__title">Your session has expired</h1>
        </div>
        <div className="session-expired__footer">
          <button onClick={handleLogin} className="btn-primary">Login</button>
        </div>
      </div>
    </div>
  )
}

export default SessionExpiredModal
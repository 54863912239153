import axios from "axios";

const BASE_URL = "https://genai-api.nawatech.co/";
// const BASE_URL = "http://localhost:8000";

let sessionExpiredHandler = null;
let isLocked = false;

export const setSessionExpiredHandler = (handle) => {
    sessionExpiredHandler = handle;
}

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
});

axiosInstance.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
})

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if(error.response?.status === 401){
            if (!isLocked) {
                isLocked = true;
                if(sessionExpiredHandler){
                    sessionExpiredHandler();
                }
                isLocked = false;
            }
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
)
import { Outlet, useParams } from "react-router-dom";
import NotFoundPage from "../NotFoundPage";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const DashboardValidator = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    // const listDashboard = {
    //     'mvd-production': {
    //         dataset_id: '493bb9e1-bf53-470d-8c2e-0199d53e64b8',
    //         report_id: 'cb99debc-2aea-443a-86bd-cd591ae84560',
    //         content_pane_visible : false,
    //         filter_pane_enabled : true,
    //         role: user.role.production?.name,
    //         title: 'MVD Production'
    //     },
    //     'icon360-spb-exception': {
    //         dataset_id: '75f967b2-8b65-400c-90e0-c8d2a4116514',
    //         report_id: '3a4858a7-7862-49e9-a683-84d155bd21c1',
    //         content_pane_visible : true,
    //         filter_pane_enabled : true,
    //         role: user.role.production?.name,
    //         title: 'iCON360 - SPB Exception'
    //     },
    //     'icon360-ipd-effectiveness': {
    //         dataset_id: '678be081-6f3a-400d-9ff9-9772d8c76177',
    //         report_id: '33e8dfac-dbd2-4cfe-959c-6d9d3deae43f',
    //         content_pane_visible : true,
    //         filter_pane_enabled : true,
    //         role: user.role.production?.name,
    //         title: 'iCON360 - IPD Effectiveness'
    //     },
    //     'icon360-unreconciled-denda':{
    //         dataset_id: '3a942eca-62e3-4ae7-839d-f940e5be8413',
    //         report_id: '2d889b1c-ab72-4932-9729-6f321ad0fed0',
    //         content_pane_visible : true,
    //         filter_pane_enabled : true,
    //         role: user.role.production?.name,
    //         title: 'iCON360 - Unreconciled Denda'
    //     }
    // }

    const params = useParams('dashboard_id')
    const dashboard = user.dashboard.find(dashboard => dashboard.slug === params.dashboard_id);
    return dashboard ? <Outlet context={dashboard} /> : <NotFoundPage />
}

export default DashboardValidator;
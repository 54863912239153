import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDDesktop from './MVDDesktop/MVDDesktop';
import "./App.css";
import PrivateRoutes from './auth/PrivateRoute';
import NotFoundPage from './NotFoundPage';
import DashboardValidator from './auth/DashboardValidator';
import MainLayout from './components/MainLayout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={'/home'} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="/home" element={<Homepage />} />
            <Route element={<DashboardValidator />}>
              <Route path="/dashboard/:dashboard_id" element={<MVDDesktop />} />
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./EvaButton.css";
import Eva from "../assets/Eva.png";
import EvaDashboard from "../EvaDashboard/EvaDashboard";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../libs/redux/slices/sidebarSlices";
import Draggable from "react-draggable";
import Bot from "../Bot";

const EvaButton = () => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const [btnSize, setBtnSize] = useState({
    eva_btn_width: '80px',
    eva_btn_height: '30px',
    eva_btn_image: '30px',
    eva_btn_font_size: '16px',
  });

  const currentSize = useSelector((state) => state.sizePage.size);
  const [isMove, setIsMove] = useState(false);

  const onClickMoveable = () => {
    if (isMove) {
      setIsMove(false)
    } else {
      setIsMove(true)
    }
  }

  const handleResize = (currentSize) => {
    if(!currentSize) return;
    if(currentSize < 1024) {
      const evaBtnWidth = 50;
      const evaBtnHeight = 20;
      const evaBtnImage = 20;
      setBtnSize({
        eva_btn_width: `${evaBtnWidth}px`,
        eva_btn_height: `${evaBtnHeight}px`,
        eva_btn_image: `${evaBtnImage}px`,
        eva_btn_font_size: `${evaBtnHeight * 0.5}px`,
      });
      return;
    }
    const evaBtnWidth = currentSize * 0.05;
    const evaBtnHeight = evaBtnWidth / 2 - (evaBtnWidth * 0.1);
    const evaBtnImage = evaBtnHeight;
    setBtnSize({
      eva_btn_width: `${evaBtnWidth}px`,
      eva_btn_height: `${evaBtnHeight}px`,
      eva_btn_image: `${evaBtnImage}px`,
      eva_btn_font_size: `${evaBtnHeight * 0.5}px`,
    });
  }

  useEffect(() => {
    handleResize(currentSize);
  }, [currentSize]);

  return (
    <>
      <Draggable disabled={!isMove} defaultPosition={{ x: 0, y: 10 }}>
        <div className={isMove ? 'moveable' : ''}>
          <button className="eva-btn" style={{ width: btnSize.eva_btn_width, height: btnSize.eva_btn_height, borderRadius:btnSize.eva_btn_image }} onClick={() => dispatch(toggleSidebar())} disabled={isMove}>
            <span className="eva-btn-text" style={{ marginLeft: btnSize.eva_btn_image, fontSize: btnSize.eva_btn_font_size }}>EVA</span>
          </button>
          <img className="eva-btn-image" style={{ width: btnSize.eva_btn_image, height: btnSize.eva_btn_image }} alt="EVA" src={Eva} onDoubleClick={onClickMoveable} />
        </div>
      </Draggable>
      <div className={`sliding-div ${isSidebarOpen ? "visible" : ""}`}>
        <Bot />
      </div>
    </>
  );
};

export default EvaButton;

import React from "react";
import "./EvaDashboard.css";
import Eva from "../assets/Eva.png";
import Dash from "../assets/dash.svg";
// import Layer from "../assets/layer.svg"
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../libs/redux/slices/sidebarSlices";


const EvaDashboard = () => {
  const dispatch = useDispatch();
  return (
    <div className="eva-dashboard">
      <div className="overlap-group">
        <img className="ellipse" alt="Ellipse" src={Eva} />
        <div className="text-wrapper">Eva</div>
      </div>
      <div className="group-wrapper">
        <img
          className="cursor-pointer"
          src={Dash}
          alt=""
          onClick={() => dispatch(toggleSidebar())}
        />
      </div>
    </div>
  );
};

export default EvaDashboard;
